<template>
  <div class="setting">
    <div class="setting_item flex" v-for="(item, index) in settingList" :key="index" @click="itemClick(item)">
      <div class="item-name">{{item.name}}</div>
      <van-icon name="arrow" size="0.5rem" color="#90979c"/>
    </div>
    <div class="footer" v-if="isShowLoginOut">
      <van-button type="default" @click="logout">退出登录</van-button>
    </div>

    <div class="dialog" v-show="dialogShow">
      <div class="box">
        <div class="box-content">确认退出登录？</div>
        <div class="box-button flex">
          <van-button type="default" plain @click="dialogShow = false">取消</van-button>
          <van-button type="info" @click="confirm" color="#3F7C53">确认</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, Button } from 'vant'
import { logout } from '@/utils/api'
import common from '@/utils/common'
import {uwStatisticAction} from "../../utils/util";
export default {
  components:{
    [Icon.name]: Icon,
    [Button.name]: Button
  },
  data() {
    return {
      settingList: [
        {name: '账号管理', to: '/main/manage'},
        {name: '关于', to: '/main/relate'}
      ],
      dialogShow: false,
      isShowLoginOut:false,
    }
  },
  mounted() {

      this.isShowLoginOut = common.getItem('phoneNo') != undefined;

      console.log('this.isShowLoginOut',this.isShowLoginOut,common.getItem('phoneNo'))
    uwStatisticAction('/setting','设置')
  },
  methods: {
    itemClick(item) {
      this.$router.push(item.to)
    },
    logout() {
      this.dialogShow = true
    },
    confirm() {
      logout({
        phoneNo: common.getItem('phoneNo'),
        token: common.getItem('wtToken')
      }).then(res=>{
        this.dialogShow = false
        localStorage.removeItem('wtToken')
        localStorage.removeItem('phoneNo')
        localStorage.removeItem('userName')
        localStorage.removeItem('userRole')
        localStorage.removeItem('uniqueNo')
        localStorage.removeItem('isAudit')
        localStorage.removeItem('signZZ')
        localStorage.removeItem('authStep')
        localStorage.removeItem('noRealName')
        localStorage.removeItem('masterNo')
        localStorage.removeItem('jumpFrom')
        localStorage.removeItem('newsHomeScrollTop')

        this.$store.dispatch('keepAliveState/addKeepAliveAction', 'login')
        this.$router.push({name:'mine'})
      })
    }
  }
}
</script>

<style lang="less" scoped>
.setting{
  background: #f5f5f5;
  height: 100%;
  padding: 0.2rem 0 0;
  .flex{
    display: flex;
  }
  .setting_item{
    background: #fff;
    height: 1rem;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.01rem solid #ccc;
    .item-name{
      margin-left: 0.3rem;
      font-size: 0.32rem;
    }
    .item-left{
      align-items: center;
      .van-icon{
        width: 0.7rem;
        text-align: center;
      }
    }
    &:nth-last-child(1){
      border-bottom: 0;
    }
  }
  .footer{
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    .van-button{
      width: 6rem;
    }
  }
  .dialog{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    .box{
      width: 5rem;
      height: 3rem;
      background: #fff;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 30%;
      border-radius: 0.2rem;
      z-index: 10;
      .box-content{
        text-align: center;
        margin-top: 0.6rem;
        font-size: 0.35rem;
        color: #333;
      }
      .box-button{
        margin-top: 0.8rem;
        justify-content: center;
        .van-button{
          width: 2.2rem;
          height: 0.7rem;
          line-height: 0.7rem;
          border-radius: 0.35rem;
          margin-right: 0.3rem;
          &:nth-last-child(1){
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>
